@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "../../../node_modules/tom-select/dist/css/tom-select.css";


@layer base {
    :root {
        --color-text-base: 38, 38, 38; /* #262626 */
        --color-text-inverted: 251, 191, 36; /*  #FBEB24 */
        --color-text-highlight: 251, 191, 36; /*  #FBEB24 */
        --color-text-muted: 253, 230, 138; /* #FDE6A0 */
        --color-fill: 251, 191, 36; /*  #FBEB24 */
        --color-highlight: 163, 230, 53; /*  #A3E835 */
        --color-background: 241, 245, 249; /*  #f1f5f9 */
        --color-background-inverted: 38, 38, 38; /* #262626 */
        --color-background-inverted-muted: 82, 82, 82; /* #525252 */
        --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
    }

    @media (prefers-color-scheme: light) {
        :root {
            --color-text-base: 255, 255, 204; /* #FFFFCC */
            --color-text-highlight: 251, 191, 36; /* #FBEB24 */
            --color-text-inverted: 251, 191, 36; /* #FBEB24 */
            --color-text-muted: 253, 230, 138; /* #FDE6A0 */
            --color-fill: 251, 191, 36; /*  #FBEB24 */
            --color-highlight: 163, 230, 53; /*  #A3E835 */
            --color-background: 241, 245, 249; /*  #f1f5f9 */
            --color-background-inverted: 82, 82, 82; /* #525252 */
            --color-background-inverted-muted: 64, 64, 64; /* #525252 */
            --map-tiles-filter: saturate(0.2) brightness(0.7);
        }
    }
}

@layer components {
}

.ts-wrapper {
    @apply w-full ml-0;
}

.ts-control {
    @apply
    shadow-sm
    rounded-lg
    border-gray-300
    bg-white
    px-0.5
    px-2
    leading-none
    text-sm;

    /* Add caret https://github.com/orchidjs/tom-select/discussions/384 */
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
}

.ts-dropdown {
    @apply rounded-md border border-solid border-t border-gray-300 text-base z-40 bg-white;
}

.ts-dropdown-content {
    @apply z-0;
}

.ts-dropdown [data-selectable].option:first-child {
    @apply rounded-t-lg;
}

.ts-dropdown [data-selectable].option:last-child {
    @apply rounded-b-lg;
}

.ts-dropdown .create:hover, .ts-dropdown .option:hover {
    @apply bg-sky-50 text-sky-900;
}

.ts-dropdown .active {
    @apply bg-gray-100 text-gray-900;
}
